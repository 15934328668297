/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS } from 'immutable';
import classNames from 'classnames';
import { versionIndexName } from '../../../utils/utils';
import styles from './EmissionsWaste.module.scss';
import '../AnalyzerComponents.scss';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';

import BitaSpinner from '../../BitaSpinner/BitaSpinner';
import BitaSearchBar from '../../../bitaComponents/BitaSearchBar/BitaSearchBar';
import BitaSelect from '../../BitaSelect/BitaSelect';
import ConfigYAML from '../../../config/ConfigYAML';
import { selectUniverse } from '../../../state/actions/universe-actions';
import { updateUniverse, updateMethodology } from '../../../state/actions/module-selection-actions';
import { getIndexBenchmark } from '../../../state/actions/benchmark-actions';
import * as Action from '../../../state/actions/api-data-actions';
import RunBacktestModal from '../../../pages/runBacktestModal';
import RunRebalancingReconstitution from '../../../pages/runRebalancingReconstitution';
import { DownloadCenterModal } from '../../BitaModal/DownloadCenterModal';

import { IconsModules } from '../../../constants/icons-module';
import IndexDescription from '../IndexDescription';
import DownloadCenter from '../DownloadCenter';
import BarGraphWithDropdown from './charts/BarGraphWithDropdown';
import TopConstituentTable from './charts/TopConstituentTable';
import LinearChartWithDropdown from './charts/LinearChartWithDropdown';
import TableIndexBenchmarkByYear from './charts/TableIndexBenchmarkByYear';

const decarbonDropdown = [
  { id: 'scope', value: 'Scopes' },
  { id: 'totalA', value: 'Total A' },
  { id: 'totalB', value: 'Total B' },
];

const compoundedDropdown = [
  { id: 'total A', value: 'Total A' },
  { id: 'total B', value: 'Total B' },
];
const decarbonizationDropdownOptions = [
  { id: '1Y', value: '1 Year' },
  { id: '3Y', value: '3-Year' },
];
const styleContent = {
  width: 'calc(100% - 5px)',
  display: 'flex',
  alignItems: 'center',
  margin: '5px 10px 5px 0px',
  borderRadius: '5px',
  backgroundColor: '#3b3f44',
  height: '60px',
};

const PerformanceAndRisk = ({ benchmarkList }) => {
  const [indexStats, setIndexStats] = useState(fromJS({}));
  const [pseudoTimeout, setPseudoTimeout] = useState(false);
  const [benchmarkStats, setBenchmarkStats] = useState(fromJS({}));
  const [lastYearDateList, setLastYearDateList] = useState([]);
  const optionsBaseDefault = benchmarkList || [];
  const [riskModel, setRiskModel] = useState(11);
  const [optionsBase, setOptionsBase] = React.useState(optionsBaseDefault[0]);
  const [contributorsDate, setConstributorDate] = useState({});
  const [contributorsList, setContributorsList] = useState({});
  const apiData = useSelector(state => state.apiData);
  const company = useSelector(state => state.auth.company);
  const backtestData = useSelector(state => state.backtest);
  const [indexBenchmark, setIndexBenchmark] = useState();
  const [normalizedIntensityType, setNormalizedIntensityType] = useState('zscore');
  const [ghgEmissions, setGhgEmission] = useState({});
  const [sectorNormalizedData, setSectorNormalizedData] = useState({});
  const [decarbonizationData, setDecarbonization] = useState({});
  const [coTwoEqYear, setCoTwoEqYear] = useState({});
  const [dataCoEq, setDataCoEq] = useState({});
  const [decaborTimeframe, setDecaborTimeframe] = useState({ id: '1Y', value: '1 Year' });
  const [carbonIntensity, setCarbonIntensity] = useState({});
  const [carbonTimeframe, setCarbonTimeframe] = useState({ id: '1Y', value: '1 Year' });
  const dispatch = useDispatch();
  const modalRef = useRef();
  const ModalRRRef = useRef();

  const index_list = _.get(apiData, ['apiData', 'Indexes', 'data'], []);
  const currentIndexSelected = useSelector(
    state => state.apiData.apiData['Current Index Selected'],
  );
  const token = useSelector(state => state.auth.token);

  const { indexId } = useParams();

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Index Stats']) {
      setIndexStats(fromJS(apiData.apiData['Index Stats']));
    }
  }, [apiData.apiData['Index Stats']]);

  useEffect(() => {
    // Generate Year Dropdown options
    const dateList = _.get(currentIndexSelected, ['data', 'dates_constitutions_real'], false);
    if (dateList) {
      const yearList = [];
      Object.values(dateList)
        .filter(ddate => new Date(ddate) >= new Date('2014-01-01'))
        .forEach(dcr => {
          const year = new Date(dcr).getFullYear();
          if (yearList.filter(yl => yl.id === year).length === 0) {
            yearList.push({ id: year, value: year });
          }
        });
      yearList.sort((a, b) => Number(b.value) - Number(a.value));
      setLastYearDateList(yearList);
      setConstributorDate(yearList[0]);
      setCoTwoEqYear(yearList[0]);
    }
  }, [currentIndexSelected]);

  useEffect(() => {
    // Effect to prepare data Decarbonization
    if (indexStats && benchmarkStats) {
      const _decarbonization = {
        '1Y': {
          'Scope 1': { ...indexStats.getIn(['data', '177', 'value'])?.toJS() } || {},
          'Scope 2': { ...indexStats.getIn(['data', '178', 'value'])?.toJS() } || {},
          'Scope 3': { ...indexStats.getIn(['data', '179', 'value'])?.toJS() } || {},
          'Total A': { ...indexStats.getIn(['data', '175', 'value'])?.toJS() } || {},
          'Total A - Benchmark':
            { ...benchmarkStats.getIn(['data', '175', 'value'])?.toJS() } || {},
          'Total B': { ...indexStats.getIn(['data', '176', 'value'])?.toJS() } || {},
          'Total B - Benchmark':
            { ...benchmarkStats.getIn(['data', '176', 'value'])?.toJS() } || {},
        },
        '3Y': {
          'Scope 1': { ...indexStats.getIn(['data', '182', 'value'])?.toJS() } || {},
          'Scope 2': { ...indexStats.getIn(['data', '183', 'value'])?.toJS() } || {},
          'Scope 3': { ...indexStats.getIn(['data', '184', 'value'])?.toJS() } || {},
          'Total A': { ...indexStats.getIn(['data', '180', 'value'])?.toJS() } || {},
          'Total B': { ...indexStats.getIn(['data', '181', 'value'])?.toJS() } || {},
          'Total A - Benchmark':
            { ...benchmarkStats.getIn(['data', '180', 'value'])?.toJS() } || {},
          'Total B - Benchmark':
            { ...benchmarkStats.getIn(['data', '181', 'value'])?.toJS() } || {},
        },
      };
      setDecarbonization(_decarbonization);
    }
  }, [indexStats, benchmarkStats]);

  useEffect(() => {
    // Effect to prepare data Carbon Intensity Growth Chart
    if (indexStats && benchmarkStats) {
      const _carbonIntensity = {
        '1Y': {
          'Total A': {
            Index: { ...indexStats.getIn(['data', '251', 'value'])?.toJS() } || {},
            Benchmark: { ...benchmarkStats.getIn(['data', '251', 'value'])?.toJS() } || {},
          },
          'Total B': {
            Index: { ...indexStats.getIn(['data', '253', 'value'])?.toJS() } || {},
            Benchmark: { ...benchmarkStats.getIn(['data', '253', 'value'])?.toJS() } || {},
          },
        },
        '3Y': {
          'Total A': {
            Index: { ...indexStats.getIn(['data', '252', 'value'])?.toJS() } || {},
            Benchmark: { ...benchmarkStats.getIn(['data', '252', 'value'])?.toJS() } || {},
          },
          'Total B': {
            Index: { ...indexStats.getIn(['data', '254', 'value'])?.toJS() } || {},
            Benchmark: { ...benchmarkStats.getIn(['data', '254', 'value'])?.toJS() } || {},
          },
        },
      };
      setCarbonIntensity(_carbonIntensity);
    }
  }, [indexStats, benchmarkStats]);

  useEffect(() => {
    // Effect to prepare data Carbon Intensity Growth Chart
    if (indexStats && benchmarkStats) {
      const _normalizedIntensity = {
        zscore: {
          'Total A': { ...indexStats.getIn(['data', '189', 'value'])?.toJS() } || {},
          'Total B': { ...indexStats.getIn(['data', '190', 'value'])?.toJS() } || {},
        },
        growth: {
          'Total A': { ...indexStats.getIn(['data', '191', 'value'])?.toJS() } || {},
          'Total B': { ...indexStats.getIn(['data', '192', 'value'])?.toJS() } || {},
        },
      };
      setSectorNormalizedData(_normalizedIntensity);
    }
  }, [indexStats, benchmarkStats]);

  useEffect(() => {
    // Effect to prepare data GhG
    if (indexStats) {
      const _ghg = {
        index: {
          'Total A': { ...indexStats.getIn(['data', '243', 'value'])?.toJS() } || {},
          'Total B': { ...indexStats.getIn(['data', '244', 'value'])?.toJS() } || {},
        },
        benchmark: {
          'Total A': { ...benchmarkStats.getIn(['data', '243', 'value'])?.toJS() } || {},
          'Total B': { ...benchmarkStats.getIn(['data', '244', 'value'])?.toJS() } || {},
        },
      };
      setGhgEmission(_ghg);
    }
  }, [indexStats]);

  const buildObjectCoTwoEQ = (valuesArray, subcategory, accumulativeObject) => {
    valuesArray.forEach(tc => {
      const year = new Date(tc.timestamp).getFullYear();
      if (accumulativeObject[year]) {
        const auxObj = accumulativeObject[year];
        Object.assign(auxObj, { [subcategory]: tc.value });
        accumulativeObject[year] = auxObj;
      } else {
        Object.assign(accumulativeObject, { [year]: { [subcategory]: tc.value } });
      }
    });
    return accumulativeObject;
  };

  useEffect(() => {
    const totalA = indexStats.getIn(['data', '243', 'value'])?.toJS();
    const totalB = indexStats.getIn(['data', '244', 'value'])?.toJS();
    const benchmarkA = benchmarkStats.getIn(['data', '243', 'value'])?.toJS();
    const benchmarkB = benchmarkStats.getIn(['data', '244', 'value'])?.toJS();
    let coTwoEq = {};
    if (totalA) {
      coTwoEq = buildObjectCoTwoEQ(totalA, 'INDEX Carbon Intensity Total A=Scope 1+2+3', coTwoEq);
    }
    if (totalB) {
      coTwoEq = buildObjectCoTwoEQ(totalB, 'INDEX Carbon Intensity Total B=Scope 1+2', coTwoEq);
    }
    if (benchmarkA) {
      coTwoEq = buildObjectCoTwoEQ(
        benchmarkA,
        'BENCHMARK Carbon Intensity Total A=Scope 1+2+3',
        coTwoEq,
      );
    }
    if (benchmarkB) {
      coTwoEq = buildObjectCoTwoEQ(
        benchmarkB,
        'BENCHMARK Carbon Intensity Total B=Scope 1+2',
        coTwoEq,
      );
    }
    setDataCoEq(coTwoEq);
  }, [indexStats, benchmarkStats]);

  useEffect(() => {
    let constributorData = indexStats.getIn(['data', '102', 'value'])?.toJS();
    if (constributorData) {
      const contibutorObject = {};
      const assets = _.get(currentIndexSelected, ['data', 'assets_descriptors'], {});
      constributorData.forEach(tc => {
        const year = new Date(tc.timestamp).getFullYear();

        const yearValues = Object.entries(tc.value).map(([isin, values]) => {
          return {
            'Security Name': assets[isin]?.name || '',
            carbon_intensity: Number(values['Carbon Intensity']?.toFixed(4)) || '0',
            'Relative Intensity': (Number(values['Relative Intensity']) * 100).toFixed(2),
          };
        });
        yearValues.sort((a, b) => Number(b.carbon_intensity) - Number(a.carbon_intensity));
        Object.assign(contibutorObject, { [year]: yearValues });
      });
      constributorData = undefined;
      setContributorsList(contibutorObject);
    } else {
      setContributorsList({});
    }
  }, [indexStats]);

  useEffect(() => {
    if (apiData.apiData && apiData.apiData['Benchmark Card Stats']) {
      setBenchmarkStats(fromJS(apiData.apiData['Benchmark Card Stats']));
    }
  }, [apiData.apiData['Benchmark Card Stats']]);

  let ImageI;
  if (currentIndexSelected?.data?.index_type === 'regular') {
    ImageI = IconsModules.IndexModule;
  } else if (currentIndexSelected?.data?.index_type === 'optimized_index') {
    ImageI = IconsModules.OptimizedModule;
  } else if (currentIndexSelected?.data?.index_type === 'basket_index') {
    ImageI = IconsModules.PortfolioBasketModule;
  } else {
    ImageI = IconsModules.StructureIModule;
  }

  useEffect(() => {
    if (currentIndexSelected.data && currentIndexSelected.data.benchmark_id) {
      setIndexBenchmark(currentIndexSelected.data.benchmark_id);

      const usedBenchmark = optionsBaseDefault.filter(
        bench => bench.id === currentIndexSelected.data.benchmark_id,
      );
      if (usedBenchmark.length > 0) setOptionsBase(usedBenchmark[0]);
      const riskModelList = _.get(
        apiData,
        ['apiData', 'risk_models', 'data', 'Risk Model Bita'],
        [],
      );
      const risk = riskModelList.filter(r => r.name === currentIndexSelected.data.risk_name);
      if (risk.length > 0) {
        setRiskModel(risk[0]);
      }
    }
  }, [currentIndexSelected, benchmarkList]);

  useEffect(() => {
    if (indexId && indexBenchmark) {
      getIndexBenchmark(indexId, indexBenchmark, token).then(resp => {
        dispatch(
          Action.setApiData({
            title: 'Benchmark Card Stats',
            data: resp.data,
          }),
        );
      });
    }
  }, [indexBenchmark]);

  useEffect(() => {
    if (indexId) {
      dispatch(Action.setCurrentIndex(token, indexId));
      dispatch(Action.setIndexStats(token, indexId));
    }
  }, [indexId]);

  const isSameAsBacktest = () => {
    if (
      backtestData &&
      currentIndexSelected &&
      currentIndexSelected.data &&
      backtestData.id === currentIndexSelected.data.id
    ) {
      return true;
    }
    return false;
  };

  const filesDownloadCenter = isSameAsBacktest()
    ? _.get(backtestData, 'pdfs', {
        status: 'loading',
      })
    : _.get(apiData, 'apiData.[Download Center]', {
        status: 'loading',
      });

  const timer = ConfigYAML.jsonPath(['analyzer', 'timeout_files']);
  const filesSpinnerMessage = ConfigYAML.jsonPath(['analyzer', 'timeout_text']);
  useEffect(() => {
    if (indexStats.size > 0) {
      setTimeout(() => {
        setPseudoTimeout(true);
      }, timer);
    }
  }, [indexStats]);

  const getMethodology = idMeth => {
    const allMethodologies = _.get(apiData, 'apiData.[Methodologies]', {});
    const selectedMeth = allMethodologies.data.filter(m => m.id === idMeth)[0];
    if (selectedMeth) {
      dispatch(updateMethodology(selectedMeth));
      dispatch(
        Action.setApiData({
          title: 'methodologySelected',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'methodologySelectedCard',
          data: { ...selectedMeth, ...selectedMeth.rules, id: idMeth },
        }),
      );
    }
  };

  const getUniverse = idUniverse => {
    const allUniverses = _.get(apiData, 'apiData.[AllUniverses]', {});
    const selectedUni = allUniverses.data.filter(u => u.id === idUniverse)[0];
    if (selectedUni) {
      dispatch(selectUniverse(selectedUni));
      dispatch(updateUniverse(selectedUni));
      dispatch(
        Action.setApiData({
          title: 'universeSelected',
          data: selectedUni,
        }),
      );
      dispatch(
        Action.setApiData({
          title: 'universeSelectedCard',
          data: selectedUni,
        }),
      );
    }
  };

  const activeRunBackTest = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      const idUniverse = currentIndexSelected.data.universe_id;
      const idMethodology = currentIndexSelected.data.methodology_id;
      getUniverse(idUniverse);
      getMethodology(idMethodology);
      dispatch(
        Action.setApiData({
          title: 'indexBacktest',
          data: {
            'Index Backtest': {
              'INDEX NAME': versionIndexName(currentIndexSelected.data.name, index_list),
              'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
              'Start Date': currentIndexSelected.data.start_date.split('T')[0],
              'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
              Benchmark: optionsBase,
              risk_model_id: riskModel,
              RiskOption: riskModel,
            },
          },
        }),
      );
      modalRef.current.runModal();
    }
    return {};
  };

  const activeRebalanceReconstitution = () => {
    dispatch(
      Action.setApiData({
        title: 'indexBacktest',
        data: {
          'Index Backtest': {
            'PORTFOLIO NAME': versionIndexName(currentIndexSelected.data.name, index_list),
            'BASE VALUE': Math.trunc(currentIndexSelected.data.base_value),
            'Start Date': currentIndexSelected.data.start_date.split('T')[0],
            'Inception Date': currentIndexSelected.data.inception_date.split('T')[0],
            Benchmark: optionsBase,
            risk_model_id: riskModel,
            RiskOption: riskModel,
          },
        },
      }),
    );
    const idUniverse = currentIndexSelected.data.universe_id;
    const idMethodology = currentIndexSelected.data.methodology_id;
    getUniverse(idUniverse);
    getMethodology(idMethodology);
    ModalRRRef.current.runModal();
  };

  const getIndexName = () => {
    if (currentIndexSelected && currentIndexSelected.data) {
      return currentIndexSelected.data.name;
    }
    return '';
  };

  return (
    <div className={styles.base}>
      <RunBacktestModal ubication="analyzer" showData ref={modalRef} disable_title />
      <RunRebalancingReconstitution ubication="analyzer" showData ref={ModalRRRef} disable_title />
      <DownloadCenterModal />
      <div className={styles.contentContainer}>
        <div className={styles.firstHalfContainer}>
          <div className={styles.halfWidthContainer} style={{ marginRight: '7px' }}>
            <div className={styles.baseInfoContainer} style={{ marginRight: '0px' }}>
              <div className={styles.baseInfoTitleContainer}>
                <span className={styles.baseInfoTitle}>
                  {' '}
                  <ImageI /> {getIndexName()}
                </span>
              </div>
              <IndexDescription
                indexType={currentIndexSelected?.data?.index_type}
                universe={currentIndexSelected?.data?.universe || ''}
                methodology={currentIndexSelected?.data?.methodology || ''}
                start={currentIndexSelected?.data?.start_date.split('T')[0] || ''}
                end={currentIndexSelected?.data?.end_date?.split('T')[0] || ''}
                author={currentIndexSelected?.data?.author || ''}
                benchmark={currentIndexSelected?.data?.benchmark || ''}
                risk={currentIndexSelected?.data?.risk_name || ''}
                parentIndex={currentIndexSelected?.data?.parent_index}
                frontParameters={currentIndexSelected?.data?.front_parameters || {}}
              />
              {currentIndexSelected?.data?.index_type === 'regular' && (
                <div
                  className={styles.benchmarkContainer}
                  style={{
                    marginTop:
                      currentIndexSelected?.data?.index_type === 'optimized_index'
                        ? '-15px'
                        : '0px',
                  }}
                >
                  <span className={styles.benchmarkTitle}>Select New Benchmark</span>
                  <div className={styles.benchmarkSearch}>
                    <div className={styles.benchmarkSearchDropdown}>
                      <BitaSearchBar
                        searchOptions={optionsBaseDefault}
                        onChange={selected => {
                          setOptionsBase(selected);
                        }}
                        value={optionsBase?.value}
                      />
                    </div>
                    <BitaButton
                      primaryWhite
                      onClick={activeRunBackTest}
                      disabled={currentIndexSelected?.data?.index_type === 'structured_index'}
                    >
                      Recalculate
                    </BitaButton>
                  </div>
                </div>
              )}
            </div>
            <div
              className={styles.downloadContainer}
              style={{ marginRight: '0px', marginBottom: '10px' }}
            >
              <span className={styles.downloadTitle}>Download Center</span>
              {(filesDownloadCenter.status === 'done' || filesDownloadCenter?.files) &&
              pseudoTimeout ? (
                <DownloadCenter
                  files={Object.assign(
                    {
                      constituents: currentIndexSelected?.data?.constituents_url,
                      machine_readable_constituents_url:
                        currentIndexSelected?.data?.machine_readable_constituents_url,
                      machine_readable_constituents_summary_url:
                        currentIndexSelected?.data?.machine_readable_constituents_summary_url,
                      machine_readable_basket_management_constituents_url:
                        currentIndexSelected?.data
                          ?.machine_readable_basket_management_constituents_url,
                      price: currentIndexSelected?.data?.price_series_url,
                      guidebook: currentIndexSelected?.data?.guidebook_url,
                    },
                    filesDownloadCenter.files,
                  )}
                  company={company}
                  indexType={currentIndexSelected?.data?.index_type}
                  frontParameters={currentIndexSelected?.data?.front_parameters || {}}
                />
              ) : (
                <div>
                  <BitaSpinner />
                  {filesSpinnerMessage}
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(styles.halfWidthContainer, styles.generalStatsContainer)}
            style={{ margin: '3px 10px 10px 13px', width: 'calc(50% - 20px)' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                justifyContent: 'space-between',
              }}
            >
              <span
                className={classNames(styles.styleFactorTitle, styles.title)}
                style={{ marginBottom: '15px' }}
              >
                CARBON INTENSITY
              </span>
              {(currentIndexSelected?.data?.index_type === 'regular' ||
                currentIndexSelected?.data?.index_type === 'rebalancing_module') && (
                <div style={{ position: 'relative', top: '-90px', left: '20px', display: 'flex' }}>
                  <BitaButton
                    primaryWhite
                    style={{ width: '200px', marginRight: '10px' }}
                    onClick={activeRebalanceReconstitution}
                  >
                    Rebalance Portfolio/Index
                  </BitaButton>
                </div>
              )}
            </div>
            <div className={styles.tableContainer}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
              >
                <span className={styles.styleFactorTitle} style={{ fontWeight: 'bold' }}>
                  Carbon Intensity Index vs. Benchmark
                </span>
                <BitaSelect
                  data={lastYearDateList}
                  value={coTwoEqYear}
                  clearable={false}
                  handleSelection={selected => {
                    setCoTwoEqYear(selected);
                  }}
                />
              </div>
              <TableIndexBenchmarkByYear
                data={dataCoEq}
                columns={[
                  currentIndexSelected?.data?.index_type === 'rebalancing_module'
                    ? 'Portfolio'
                    : 'Index',
                  'Benchmark',
                ]}
                year={coTwoEqYear.value}
                rows={[
                  'Carbon Intensity Total A=Scope 1+2+3',
                  'Carbon Intensity Total B=Scope 1+2',
                ]}
              />
              <div style={{ fontSize: '10px', textAlign: 'right' }}>
                Carbon Intensity = tons CO2e / Revenue $M{' '}
              </div>
            </div>
            <LinearChartWithDropdown
              title="Carbon Intensity Evolution Graph"
              stats={ghgEmissions}
              dropdownOptions={[
                { id: 'Total A', value: 'Total A' },
                { id: 'Total B', value: 'Total B' },
              ]}
              hideTitleDropdown
              tableTitle={
                currentIndexSelected?.data?.index_type === 'rebalancing_module'
                  ? 'Portfolio Historical Carbon Intensity'
                  : 'Index Historical Carbon Intensity'
              }
              yAxisLabel=""
            />
          </div>
        </div>
        <div
          className={styles.halfWidthContainer2}
          style={{ width: '100%', height: '50%', display: 'flex' }}
        >
          <div
            className={classNames(styles.halfWidthContainer, styles.generalStatsContainer)}
            style={{ margin: '3px 10px', width: 'calc(50% - 20px)', justifyContent: 'flex-start' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              <span
                className={classNames(styles.styleFactorTitle, styles.title)}
                style={{ fontSize: '14px', fontWeight: 'bold' }}
              >
                TOP 10 MOST CARBON INTENSE HOLDINGS (BY SCOPE 1 + 2)
              </span>
              <BitaSelect
                data={lastYearDateList}
                value={contributorsDate}
                clearable={false}
                width="160px"
                handleSelection={selected => {
                  setConstributorDate(selected);
                }}
              />
            </div>
            <TopConstituentTable
              data={contributorsList}
              year={contributorsDate.value}
              labels={['Security Name', 'carbon_intensity', 'Relative Intensity']}
            />
            {/* <span style={{ fontSize: '8px' }}>*CO2eq tons per USD 1M of Revenue.</span> */}
          </div>
          <div
            className={classNames(styles.halfWidthContainer, styles.generalStatsContainer)}
            style={{ margin: '3px 10px 3px 10px', width: 'calc(50% - 20px)' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginBottom: '15px',
              }}
            >
              <span
                className={classNames(styles.styleFactorTitle, styles.title, styles.titleUppercase)}
              >
                Carbon Intensity Growth Rate
              </span>
              <BitaSelect
                data={decarbonizationDropdownOptions}
                value={carbonTimeframe}
                clearable={false}
                width="160px"
                handleSelection={selected => {
                  setCarbonTimeframe(selected);
                }}
              />
            </div>
            <BarGraphWithDropdown
              title="Compound Annual Growth Rate"
              stats={carbonIntensity}
              typeIndex={currentIndexSelected?.data?.index_type}
              timeframe={carbonTimeframe.id}
              dropdownOptions={compoundedDropdown}
              tableTitle={`Compound Annual Growth Rate (${carbonTimeframe.value})`}
            />
          </div>
        </div>
        {/* 
        <div
          className={styles.halfWidthContainer2}
          style={{ width: '100%', height: '50%', display: 'flex', marginTop: '15px' }}
        >
          <div
            className={styles.halfWidthContainer}
            style={{
              width: 'calc(50% - 20px)',
              margin: '3px 10px',
              padding: '20px',
              borderRadius: '5px',
              backgroundColor: '#3b3f44',
              justifyContent: 'flex-start',
            }}
          >
            <div className={styles.title} style={{ marginBottom: '5px' }}>
              SECTOR NORMALIZED INTENSITY
            </div>
            <div style={styleContent}>
              <BitaButton
                primaryWhite={normalizedIntensityType === 'growth'}
                primary={normalizedIntensityType === 'zscore'}
                width="50%"
                style={{
                  marginRight: '10px',
                  textTransform: 'uppercase',
                  background: normalizedIntensityType === 'growth' ? '#4b5158' : '',
                  color: normalizedIntensityType === 'growth' ? '#9a9a9a' : '',
                }}
                onClick={() => setNormalizedIntensityType('zscore')}
              >
                Z-Score
              </BitaButton>
              <BitaButton
                primaryWhite={normalizedIntensityType === 'zscore'}
                primary={normalizedIntensityType === 'growth'}
                width="50%"
                style={{
                  textTransform: 'uppercase',
                  background: normalizedIntensityType === 'zscore' ? '#4b5158' : '',
                  color: normalizedIntensityType === 'zscore' ? '#9a9a9a' : '',
                }}
                onClick={() => setNormalizedIntensityType('growth')}
              >
                Growth %
              </BitaButton>
            </div>
            <BarGraphWithDropdown
              title={normalizedIntensityType === 'zscore' ? 'Z-Score' : 'Performance'}
              stats={sectorNormalizedData}
              timeframe={normalizedIntensityType}
              yAxisLabel={normalizedIntensityType === 'zscore' ? '' : '%'}
              hideDropdown
              tableTitle={
                normalizedIntensityType === 'zscore'
                  ? 'Stats (Z-Score)'
                  : 'Annual compounded rate (1 Year)'
              }
            />
          </div>
          <div
            className={styles.halfWidthContainer}
            style={{
              width: 'calc(50% - 20px)',
              margin: '3px 10px',
              padding: '20px',
              borderRadius: '5px',
              backgroundColor: '#3b3f44',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                marginBottom: '10px',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ marginBottom: '5px' }} className={styles.title}>
                EMISSIONS GROWTH (DECARBONIZATION)
              </span>
              <BitaSelect
                data={decarbonizationDropdownOptions}
                value={decaborTimeframe}
                clearable={false}
                width="160px"
                handleSelection={selected => {
                  setDecaborTimeframe(selected);
                }}
              />
            </div>
            <BarGraphWithDropdown
              title="Performance Graph (%)"
              stats={decarbonizationData}
              timeframe={decaborTimeframe.id}
              dropdownOptions={decarbonDropdown}
              tableTitle="Annual Compounded Rate"
              dropdownClearable={false}
            />
          </div>
        </div>
         */}
      </div>
    </div>
  );
};
export default PerformanceAndRisk;
