import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import FileSaver from 'file-saver';
import styles from './PerformanceAndRisk/PerformanceAndRisk.module.scss';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import { loaderAxiosDecorator, useLoadStatus } from '../../utils/loading_status_tools';
import axios from '../../Axios';
import { setDataModal } from '../../state/actions/download-actions';
import { hasValidBitaDomain } from '../../utils/utils';

const DownloadCenter = ({ indexType, files, company, frontParameters = {} }) => {
  const userData = useSelector(state => state.auth.userdata);
  const dispatch = useDispatch();
  const isSelected = useLoadStatus();
  const download_request = useSelector(state => state.download_center);
  const currentIndex = useSelector(state => state.apiData?.apiData['Current Index Selected']);

  const isBitaUser = hasValidBitaDomain(userData?.email);

  const downloadFile = (filetype, filename) => {
    if (
      filetype.indexOf('factsheet') > -1 ||
      filetype.indexOf('report') > -1 ||
      filetype.indexOf('emission') > -1 ||
      filetype.indexOf('waste') > -1 ||
      filetype.indexOf('controversy') > -1 ||
      filetype.indexOf('ratings') > -1 ||
      (filetype.indexOf('constituents') > -1 &&
        filename?.machine_readable_basket_management_constituents_url)
    ) {
      dispatch(
        setDataModal({
          open: true,
          file: filetype,
          filename,
        }),
      );
      return;
    }
    const request = loaderAxiosDecorator(
      dispatch,
      filetype,
    )(
      axios.get(`${process.env.REACT_APP_API_URL}/downloads`, {
        responseType: 'blob',
        params: { file: filename },
      }),
    );
    request.then(response => {
      FileSaver.saveAs(response.data, `${filename}`);
    });
  };
  const disableFactsheet = () => {
    if (indexType === 'structured_index' && Object.keys(frontParameters).length === 0) {
      return true;
    }
    return false;
  };
  const structureIndexType = () => {
    if (_.has(frontParameters, ['Risk Control Index'])) {
      return 'RISK CONTROL INDEX';
    }

    return indexType;
  };
  const guidebookEnable = () => {
    if (_.has(frontParameters, ['New Structured Product'])) {
      return 'STRUCTURED INDEX';
    }
    if (_.has(frontParameters, ['Leveraged Index'])) {
      return 'LEVERAGED INDEX';
    }
    if (_.has(frontParameters, ['Decrement Index'])) {
      return 'DECREMENT INDEX';
    }
    if (_.has(frontParameters, ['Risk Control Index'])) {
      return 'RISK CONTROL INDEX';
    }
    return indexType;
  };

  return (
    <div className={styles.downloadButtonsContainer}>
      <BitaButton
        primary
        height="28px"
        width="148px"
        className={styles.bitaButtonCustom}
        loading={isSelected.factsheet || download_request.factsheet}
        onClick={() => {
          switch (indexType) {
            case 'structured_index':
              downloadFile('si_factsheet', files.factsheet);
              break;
            case 'basket_index':
              downloadFile('bi_factsheet', files.factsheet);
              break;
            case 'rebalancing_module':
              downloadFile('mini_factsheet', files.factsheet);
              break;
            case 'rebalancing_file':
              downloadFile('rebalancing_file', files.factsheet);
              break;
            default:
              downloadFile('factsheet', files.factsheet);
              break;
          }
        }}
        disabled={disableFactsheet()}
      >
        {indexType !== 'rebalancing_module' ? 'Factsheet' : 'Mini Factsheet'}
      </BitaButton>
      {structureIndexType() !== 'structured_index' && (
        <BitaButton
          primary
          height="28px"
          width="148px"
          className={styles.bitaButtonCustom}
          loading={isSelected.constituents}
          onClick={() => downloadFile('constituents', files.constituents)}
          disabled={!files.constituents}
        >
          Constituents
        </BitaButton>
      )}

      {indexType !== 'rebalancing_module' && (
        <BitaButton
          primary
          height="28px"
          width="148px"
          className={styles.bitaButtonCustom}
          loading={isSelected.price}
          disabled={!files.price}
          onClick={() => downloadFile('price', files.price)}
        >
          Price Series
        </BitaButton>
      )}
      {indexType !== 'basket_index' &&
        indexType !== 'rebalancing_module' &&
        guidebookEnable() !== 'structured_index' && (
          <BitaButton
            primary
            height="28px"
            width="148px"
            className={styles.bitaButtonCustom}
            loading={isSelected.guidebook}
            onClick={() => downloadFile('guidebook', files.guidebook)}
            disabled={!files.guidebook}
          >
            Guidebook
          </BitaButton>
        )}
      {indexType !== 'structured_index' && indexType !== 'rebalancing_module' && (
        <BitaButton
          primary
          height="28px"
          width="148px"
          className={styles.bitaButtonCustom}
          loading={isSelected.report || download_request.report}
          onClick={() => {
            switch (indexType) {
              case 'basket_index':
                downloadFile('bi_report', files.report);
                break;
              default:
                downloadFile('report', files.report);
                break;
            }
          }}
        >
          Report
        </BitaButton>
      )}
      {indexType !== 'structured_index' && indexType !== 'rebalancing_module' && (
        <BitaButton
          primary
          height="28px"
          width="148px"
          loading={download_request.esg}
          onClick={() => {
            downloadFile('emission', files.report);
          }}
          disabled={moment(currentIndex?.data?.created_at).isBefore('2021-05-23')}
        >
          ESG Report
        </BitaButton>
      )}
      {structureIndexType() !== 'structured_index' &&
        (
          <BitaButton
            primary
            height="28px"
            width="148px"
            className={styles.bitaButtonCustomConstituents}
            loading={isSelected.machine}
            onClick={() => downloadFile('constituents', { ...files })}
            disabled={
              !(
                files.machine_readable_constituents_url ||
                files.machine_readable_constituents_summary_url ||
                files.machine_readable_basket_management_constituents_url
              )
            }
          >
            Machine Readable Files
          </BitaButton>
        )}

      {indexType === 'rebalancing_module' && (
        <BitaButton
          primary
          height="28px"
          width="180px"
          className={styles.bitaButtonCustom}
          loading={download_request.esg}
          onClick={() => {
            downloadFile('factors_report', files.report);
          }}
        >
          Style Factor Tilts Report
        </BitaButton>
      )}
      {indexType === 'rebalancing_module' && (
        <BitaButton
          primary
          height="28px"
          width="180px"
          className={styles.bitaButtonCustom}
          loading={download_request.esg}
          onClick={() => {
            downloadFile(
              'pcf_constituent',
              `${currentIndex.data.name.replace(/\s+/g, '_')}-PCF-Constituents-I-${
                currentIndex.data.id
              }.csv`,
            );
          }}
        >
          PCF Constituent
        </BitaButton>
      )}
      {indexType === 'rebalancing_module' && (
        <BitaButton
          primary
          height="28px"
          width="180px"
          className={styles.bitaButtonCustom}
          loading={download_request.esg}
          onClick={() => {
            downloadFile(
              'index_metadata',
              `${currentIndex.data.name.replace(/\s+/g, '_')}-index-metadata-I-${
                currentIndex.data.id
              }.csv`,
            );
          }}
        >
          Metadata
        </BitaButton>
      )}
      {indexType === 'rebalancing_module' && isBitaUser && (
        <BitaButton
          primary
          height="28px"
          width="148px"
          className={styles.bitaButtonCustom}
          onClick={() =>
            downloadFile(
              'rebalancing_file',
              `rebalancing_file-${currentIndex.data.name.replace(/\s+/g, '_')}.xlsx`,
            )
          }
        >
          Eligible Universe File
        </BitaButton>
      )}
    </div>
  );
};

export default DownloadCenter;
